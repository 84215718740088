// Footer
.footer {
	background-color: $grey-3;
	overflow: hidden;
	padding: 2rem 0;

	p {
		color: $grey-7;
		margin-bottom: 0;
		font-size: 1.3rem;
	}

	a {
		color: $grey-7;
		text-decoration: none;
		&:hover {
			color: $grey-7;
			text-decoration: none;
		}
	}

	&__left {
		text-align: center;
	}

	&__image {
		display: inline-block;
		iframe {
			width: 5rem !important;
			height: 4.3rem !important;
			margin: 0 auto 1rem;
		}
	}

	&__right {
		text-align: center;
	}
}

.block-text-lh {
	margin-bottom: 3rem;
}
