/**
 * Main carousel
 */

@keyframes bounce {
	from {
		transform: translateY(0);
	}

	to {
		transform: translateY(10px);
	}

}

.carousel {
	width: 100%;
	overflow: hidden;
	position: relative;

	&__pagination {
		position: absolute;
		width: 100%;
		text-align: center;

		li {
			display: inline-block;
			cursor: pointer;
			opacity: 0.7;

			&:first-child {
				margin-left: 0;
			}
		}
	}

	.slide {

		&:before {
			position: absolute;
			top: 0;
			left: 0;
			content: ' ';
			display: block;
			width: 100%;
			height: 100%;
			background-color: rgba(0, 0, 0, 0.5);
		}
	}

	&__slide-container {
		margin-bottom: 0;
		height: 100%;
		@extend %clearfix;

		li {
			margin-bottom: 0;
			float: left;
			width: 100%;
		}
	}


}

.carousel--banner {
	//height: 100%; /* full view port */
	// min-height: 60rem;

	.slide {
		height: 100%;
		float: left;
		list-style: none;
		position: relative;
		background-size: cover;
		background-position: center;
	}

	// this is weird use of BEM, let's evaluate whether it's ok to use
	.carousel__pagination {
		bottom: 4rem;

		li {
			border-radius: 100%;
			border: 2px solid $white;
			width: 1rem;
			height: 1rem;
			font: 0/0 a;
			margin-left: 2.5rem;

			&:hover, &.active {
				background-color: $white;
			}
		}
	}

	&__arrows {
		display: block;
		width: 100%;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		z-index: 52;
	}

	&__scroll-arrow {
		display: inline-block;
		position: absolute;
		bottom: 3rem;
		text-align: center;
		width: 100%;
		color: $white;
		z-index: 52;
		animation-name: bounce;
		animation-iteration-count: infinite;
		animation-duration: .6s;
		animation-direction: alternate;

		&__scroll, &__arrow {
			cursor: pointer;
		}
	}

	&__txt-scroll {
		display: inline-block;
		position: absolute;
		bottom: 6rem;
		text-align: center;
		width: 100%;
		color: $white;
		z-index: 52;
	}
}


.banner-item {
	position: relative;

	img {
		width: 100%;
		background-size: cover;
		background-position: center center;
	}

	&--content {
		position: absolute;
		bottom: 35%;
		left: 60%;
		width: 635px;
		margin-left: -57rem;
		text-align: left;
		color: $white;

		p {
			font-size: 18px;
			width: 70%;
			line-height: 3.2rem;
		}
	}

	&__content {
		position: absolute;
		bottom: 35%;
		left: 50%;
		width: 1140px;
		margin-left: -57rem;
		text-align: center;
		color: $white;

		p {
			font-size: 18px;
			line-height: 3.2rem;
			width: 50%;
			margin: 0 auto;
		}
	}
}
