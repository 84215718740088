.block-top-address {
	margin-bottom: 4rem;
	border: 2px solid $green-2;
	padding: 2rem 1.5rem;


	span {
		width: 7rem;
		display: inline-block;
	}
}

.connect__send-mail {
	display: block;
}

.contact-form {
	// display: none;

	.input, select, .textarea {
		font-weight: 400;
		color: $black;
		border: 1px solid $border-contact;

		&::placeholder {
			font-weight: 400;
			color: $black;
		}
	}

	.form-group {
		margin-bottom: 2rem;

		.error-message {
			position: relative;
		}
	}

	button[type='submit'] {
		margin-bottom: 3rem;
	}

}

.form-contact-area {
	margin-bottom: 4rem;
	overflow: hidden;
}

.map-area {
	height: 48rem;
	margin-bottom: 7.5rem;

	.map-content {
		font-size: 14px;
		color: #b2b2b2;
		font-weight: 400;
	}
}

.contact-page {
	margin-top: 0;
	padding-top: 10rem;

	.title {
		padding-left: 1.5rem;
		padding-right: 1.5rem;
	}

	.map-area {
		padding-left: 0;
		padding-right: 0;
		padding-bottom: 4rem;

		img {
			width: 100%;
		}
	}

	.connect {
		padding-top: 5rem;
		padding-left: 1.5rem;
		padding-right: 1.5rem;
		background-color: $white;

		.contact {
			display: none;
			background-color: $white;
		}

		.main-title--1 {
			font-weight: 600;
			font-size: 18px;
			text-align: left;
		}
	}

	.set-mb-xs {
		padding-left: 0;
		padding-right: 0;
	}

	.main-title--1 {
		margin-bottom: 0;
	}

	// .title-form-contact {
	// 	text-align: center;
	// }

	.follow__social {
		text-align: left;
	}

	.connect--change {
		border-top: 1px solid $grey19;
		padding-top: 4rem;
	}
}

.success {
	background: $primary-color;
	border-radius: 4px;
	color: $white;
	text-align: center;
	margin: 1rem auto;
	padding: 1rem;
	font-weight: 600;
}

.error-custom {
	background: $red-1;
    border-radius: 4px;
    color: #fff;
    text-align: center;
    margin: 1rem auto;
    padding: 1rem;
    font-weight: 600;
}

.form-group .error-message {
	display: block;
}


.third-party-logos {
	text-align: center;

	&--always-left {
		text-align: left;
	}
}