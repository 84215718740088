// tabs
.tabs {
	&__control {
		width: 100%;
		text-align: center;

		li {
			margin: 0;
			display: inline-block;
			text-align: center;
		}

		a {
			transition: color 0.3s linear;
		}

		&--1 {
			display: table;

			li {
				vertical-align: middle;
				display: table-cell;
				border-left: 1px solid $grey-1;

				&:first-child {
					border-left: none;
				}
			}

			a {
				color: $grey-7;
				transition: all 0.3s ease;

				&.text {
					font-size: 40px;
					font-weight: 700;

					&:hover, &.active {
						color: $green-1;
					}
				}

				&:hover, &.active {

					.grey {
						display: none;
					}

					.colorful {
						display: inline-block;
					}
				}
			}

			.grey, .colorful {
				transition: all 0.3s ease;
			}

			.colorful {
				display: none;
			}
		}

		&--2 {
			li {
				border-left: 1px solid $grey-5;

				&:first-child {
					border-left: 0;
				}
			}

			a {
				padding: 1rem 5.5rem;
				font-size: 13px;
				font-weight: 400;
				color: $grey-5;
				text-transform: uppercase;
				display: block;

				&:hover, &.active {
					color: $black;
				}
			}
		}
	}

	&__content {
		overflow: hidden;
		height: 100vh;
		position: relative;
		min-height: 70rem;
	}

	&--trademark {
		background-color: $white;
		margin-top: 10rem;
	}


}

.tabs-item {
	// max-width: 1140px;
	// margin: 0 auto;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);

	.thumb {
		img {
			width: 100%;
		}
	}

	.desc {
		.sub-heading.sub-heading {
			margin-bottom: 2rem;
			font-size: 4.4rem;

			& + strong {
				margin-top: 0;
			}
		}

    	p {
    		font-size: 16px;
    		line-height: 2;
    	}

    	strong {
    		font-weight: 600;
    		margin: 0.8rem 0 0.6rem;
    		display: inline-block;
    	}

    	&.reset-margin-strong {
    		strong {
    			margin: 0;
    		}
    	}
    }

    &--1 {

		.thumb {
			max-height: 365rem;
			text-align: center;

			img {
				height: 100%;
				width: auto;
			}
		}

		.desc {
			margin-top: 2rem;
			max-width: 42rem;

			strong {
				font-size: 16px;
				margin-bottom: 2rem;
			}

			.button {
				margin-top: 2.5rem;
			}
		}
    }

    &--bg-black {

    	.desc {
			color: $white;
    	}

    	.sub-heading {
    		color: $white;
    	}
    }

    &--value {
    	top: 60%;
    	transform: translateY(-70%);
    }
}















