.faq, .recruit {
	.btn-block {
		margin-bottom: 8rem;
		text-align: center;
	}

	.button--black {
		width: 100%;
		margin-bottom: 0;
	}
}

.faq-content, .recruit-content {

	.block-position-detail {
		margin-bottom: 2rem;

		.panel-heading {
			position: relative;
			border: 1px solid $grey-light;
			border-right: none;

			a {
				border-right: 1px solid $grey-light;
			}

			a:after {
				position: absolute;
				top: 50%;
				right: 0;
				transform: translateY(-50%);
				font-size: 3rem;
				font-weight: 600;
				width: 5rem;
				text-align: center;
			}

			a[aria-expanded="false"] {
				&:after {
					content: "+";
				}
			}


			a[aria-expanded="true"] {

				&:after {
					content: '-';
					font-size: 4rem;
					line-height: 1;
				}
			}
		}
	}
}

.faq-content {
	padding-left: 0;
	padding-right: 0;
	padding-bottom: 6rem;
	margin-top: 9rem;
	margin-bottom: 0;
}


.block-menu-right {

	ul {
		margin-bottom: 0;
	}

	.button {
		margin-top: 4rem;
		padding-left: 2rem;
		padding-right: 2rem;
	}
}

.sidebar-right {
	// padding-top: 4rem;
	display: none;
}

.panel-body--faq {
	line-height: 2;
}
