/**
 * Mobile layout
 */
// .main {
// 	height: 100%;
// }

.hero-banner {
	height: 100%;
}

// Responsive images (ensure images don't scale beyond their parents)
.img-responsive {
	@include img-responsive;
	margin: 0 auto;
}


.arrow-right {
	border-top: 6px solid transparent;
	border-bottom: 6px solid transparent;
	border-left: 6px solid $black;

	display: block;
	width: 0;
	height: 0;
	position: absolute;
	left: 0;
	content: "";
	top: 4.5rem;
}


.fanpage {
	margin-bottom: 2rem;
	overflow: hidden;
}

.block-menu-right {
	ul {
		li {
			position: relative;
			padding: 4rem 0 2rem 2rem;
			margin-bottom: 0;
			border-bottom: 1px solid $grey19;

			&.active a{
				color: $green-1;
				transition: color 0.3s ease;

				&:before {
					border-left-color: $green-1;
					transition: border 0.3s ease;
				}
			}

			a {
				transition: color 0.3s ease;

				&:before {
					@extend .arrow-right;
				}
			}

			&:first-child {
				padding-top: 1rem;

				a:before {
					top: 1.5rem;
					transition: border 0.3s ease;
				}
			}
		}
	}
}

.title-face {
	position: relative;

	h3 {
		position: relative;
		font-size: 1.6rem;
		padding-left: 2rem;
		text-transform: uppercase;
		font-weight: bold;

		&:before {
			@extend .arrow-right;
			top: 0.5rem;
		}
	}
}

.set-margin-sub-15-xs {
	margin-left: -15px;
	margin-right: -15px;
}

.section-cover {
	text-align: center;

	&__content {
		color: $white;
		margin-left: auto;
		margin-right: auto;
		position: relative;

		&__title {
			padding: 0;
			margin-bottom: 4rem;
			font-size: 36px;
			line-height: 1.4;
			// letter-spacing: 0;
		}

		.button--trans {
			width: 100%;
		}
	}
}


.bg-cover-other-pr {
	padding-top: 30%;
	padding-bottom: 30%;
	position: relative;
	display: inline-block;
	width: 100%;

	&:before {
		content: ' ';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(44, 46, 55, 0.8);
	}

	img {
		visibility: hidden;
	}

	// Background of recruit
	&--recruit-thumb {
		padding-bottom: 0%;
		margin-bottom: 3rem;
	}

	// Banner of recruit & faq
	&--banner-top-static {
		padding-bottom: 10%;
		margin-bottom: 3rem;
	}
}


.block-position-detail {
	display: block;

	.panel-heading {
		position: relative;
		background-color: $grey-12;
		border-bottom: 1px solid $white;

		.panel-title {
			font-size: 1.3rem;
			text-transform: uppercase;
			margin-bottom: 0;
			-webkit-tap-highlight-color: rgba(0,0,0,0);

			a {
				padding: 1.5rem 0;
				color: $grey-13;
				display: block;
				font-weight: 400;

				&:after {
					font-size: 3rem;
					font-weight: 700;
					content: '+';
					display: inline-block;
					position: absolute;
					top: 0;
					right: 0;
					height: 4.7rem;
					padding: 0 1.5rem;
				}

				&:focus {
					background: none;
					outline: none;
				}
			}

			// style title for faq
			&--faq {
				padding: 0 5rem 0 15px;
				text-transform: none;
				font-size: 1.6rem;
				font-weight: 600;
			}
		}

		&.activeCollapse {
			background-color: $green-2;

			a {
				color: $white;

				&:after {
					content: '_';
					top: -1rem;
					padding: 0 2rem;
					border-left-color: $white;
				}
			}

			// style status Active tab for faq
			&--faq {
				background: $white;

				a {
					color: $black;
				}
			}
		}
	}

	.panel-body {
		padding: 2rem 0 3rem;

		&--faq {
			padding: 15px;
		}

		.tooltip-item__description__title {
			font-weight: 600;
		}
	}
}

.lang-country{
	// background: url("../img/vi.png") 0 0 no-repeat;
	background-size: 30px;
	width: 3rem;
	height: 3rem;
}

// .lang-en {
// 	@extend .lang-vi;
// 	background-image: url("../img/us.png");
// }

// .lang-zh {
// 	@extend .lang-vi;
// 	background-image: url("../img/china.png");
// }


.tabpane {
	display: none !important;

	&.active {
		display: block !important;
	}
}

.carousel--banner {
	background: $black;
}

.not-found {
	margin-top: 10rem;
	padding: 2rem 0;
}
