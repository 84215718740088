//trademark-export
$grey-radius: #d8d8d8;

.trademark-export-page {

	.header {
		background-color: red;
	}

	.introduce {
		.international {
			p {
				background-color: $green-2;
			}
		}
	}

	.tabs--trademark {

		.export-desc {
			background-color: $green-1;
		}
	}
}