@import 'grid-framework';

//
// Grid system from Bootstrap 3.3.6
// --------------------------------------------------


// Container widths
//
// Set the container width, and override it for fixed navbars in media queries.

.container {
	@include container-fixed;
	transition: width .5s ease-out;

	@media (min-width: $screen-sm-min) {
		width: $container-sm;
	}
	@media (min-width: $screen-md-min) {
		width: $container-md;
	}
	@media (min-width: $screen-lg-min) {
		width: $container-lg;
	}
}


// Fluid container
//
// Utilizes the mixin meant for fixed width containers, but without any defined
// width for fluid, full width layouts.

.container-fluid {
	@include container-fixed;
}


// Row
//
// Rows contain and clear the floats of your columns.

.row {
	@include make-row;
}


// Columns
//
// Common styles for small and large grid columns

@include make-grid-columns;


// Extra small grid
//
// Columns, offsets, pushes, and pulls for extra small devices like
// smartphones.

@include make-grid(xs);

