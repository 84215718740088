.product-sub {
	overflow: hidden;

	a {
		display: inline-block;
		min-height: 4rem;
		position: relative;
		transition: all 0.2s linear;

		&:hover, &.active {
			.icon {
				color: $green-1;
			}
		}

		&:hover {
			transform: translateY(-0.2rem);
		}
	}

	.icon {
		font-size: 60px;
		color: $grey-7;
		line-height: 0;
		max-height: 30px;
		vertical-align: bottom;
	}
}