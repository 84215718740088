.product-landing-page {

	.product-type-list {
		padding-top: 14.4rem;
		padding-left: 20vw;
		//display: none;

		// &.active {
		// 	display: block;
		// }
	}

	.product-type-list--obeauty {
		a {
			display: block;
			overflow: hidden;
		}

		.product-type__image {
			max-height: 25rem;
			width: auto;
		}
	}

	.product-type {
		padding: 3rem 10%;
		border-bottom: 1px solid $grey-8;
		text-align: center;

		a {
			width: 100%;
		}

		&__image {
			width: 100%;
		}

		&__title {
			color: $grey-10;
			font-size: 2.4rem;
		}

	}

	.header {
		background: $white;
	}

}

.product-pages-header {
	position: fixed;
	top: 6rem;
	z-index: 40;
	width: 100%;
	// left: 0;

	&__top {
		background-color: $white;
		width: 100%;

		&:first-child {
			margin-top: 3px;
		}

		> ul {
			position: absolute;
			width: 100%;
			top: 5.3rem;
			z-index: 5;
		}

	}

	&__left {
		position: absolute;
		left: 0;
		padding: 1.2rem 1.2rem 16rem;
		width: 7rem;
		height: 100vh;
		border-right: 1px solid $grey-8;
		box-sizing: border-box;
		text-align: center;
		overflow-y: auto;

		&__filter-menu {
			width: 100%;
			font-size: 3.5rem;
			background-color: $white;
			// display: none;

			.filter-menu__text {
				display: none;
			}

			&.active {
				display: inline-block;
			}

			li {
				line-height: 0.5;
				margin: 0;
			}

			a {

				color: $grey-7;

				&.active {
					color: $green-1;
				}
			}


			.icon {
				line-height: .8;
			}

			.icon--view {
				font-size: 1.8rem;
				margin-bottom: 0.7rem;
			}

			&--obeauty {

				li {
					padding: 1.2rem 0;
				}

				.icon--view {
					padding-top: 0;
					margin-bottom: 0;
				}

			}
		}
	}
}
