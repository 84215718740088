.js-share-popover {
	position: relative;
}

.share-popover {
	display: none;
	position: absolute;
	transform: translateY(-8rem);
	// right: -2rem;
	right: 2rem;
	height: 4.2rem;
	border: 1px solid $grey19;

	ul {
		height: 100%;
		margin: 0;
	}

	li {
		display: inline-block;
	}

	&__arrow {
		position: absolute;
		// bottom: -7px;
		bottom: -1rem;
		width: 0;
		height: 0;
		right: 5rem;
		border-left: 10px solid transparent;
		border-right: 10px solid transparent;
		border-top: 10px solid $white;
	}

}


.share-popover__button.share-popover__button {
	color: $white;
	text-align: center;
	display: block;
	height: 4rem;
	line-height: 4rem;
	width: 4rem;

	&:hover {
		color: $grey-7;
	}
}

.share-popover__button.share-popover__button--black {
	color: $black;
}
