/**
 * Animations
 */

@keyframes fadeIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

.ani-fade-in {
	animation-duration: 1s;
	animation-fill-mode: both;
	animation-name: fadeIn;
}

.ani--short {
	animation-duration: 0.5s;
}

