
.recruit-content {
	margin-top: 12rem;

	&__top-title {
		font-size: 4.4rem;
	}

	&__detail {
		margin-bottom: 5rem;

		p {
			margin-bottom: 3,2rem;
			line-height: 2;
		}

		dl {

			dt {
				font-weight: 600;
				// margin-left: 2rem;
				line-height: 2;
			}

			dd {
				line-height: 2;
				margin-left: 0;
				margin-bottom: 3.2rem;
			}
		}
	}
}


