//trademark-Kềm Nghĩa
$grey-radius: #d8d8d8;

.trademark-nghia-page {
	height: 100%;

	.tabs--trademark {

		.export-desc {
			background-color: $green-1;

			p {
				text-align: center;
			}
		}
	}

	.js-content-animate-show {
		transform: translateY(0) !important;
	}

	.introduce {
		.international {
			p {
				background-color: $green-2;
			}
		}
	}
}

.trademark-obeauty-page, .trademark-omi-page, .trademark-nghia-page, .trademark-export-page {

	.carousel--banner {
		height: auto;
		min-height: auto;
	}

	.introduce {
		margin: 0 auto;
		height: auto;
		padding: 8rem $grid-gutter-width/2 4rem;
		width: 100%;

		&__content {
			overflow: hidden;

			.introduce-text {
				padding-left: 0;
				padding-right: 0;

				> p {
					color: $grey-10;
					font-size: 16px;
					line-height: 2;
				}
			}
		}

		h2 {
			color: $grey-3;
			font-size: 44px;
		}

		.thumb {
			padding-left: 0;
			padding-right: 0;
			padding-top: 3.5rem;
			margin: 0 auto;

			img {
				text-align: center;
				max-width: 90%;
				// width: 100%;
			}

		}
	}

	.bottom-array {
		display:none;
	}

	.content-arrow {
		padding:0 $grid-gutter-width/2;
	}

	.main-heading {
    	font-size: 44px;
    	padding:0 $grid-gutter-width/2;
	}

	.trademark {
		background-color: $white;
		margin: 0 auto;
		border-bottom: none;

		.sub-heading {
			margin: 0 auto;
		}
	}

	.export-item {
		margin: 0 auto;

		.title {
			font-size: 44px;
		}

		p {
			font-size: 16px;
			line-height: 2;
			color: $grey-10;
		}

		.thumb {
			text-align: center;

			&.left {
				text-align: left;
			}

			&.right {
				text-align: right;
			}

			&.center {
				text-align: center;
			}

			&.sharpen-imports {
				background-size: cover;
				background-position: center;
				padding-bottom: 150%;

			}
		}

		&--1 {
			display: block;
			width: 100%;

			.sub-heading {
				font-size: 44px;
			}

			.thumb, .desc {
				vertical-align: middle;
				padding: 8rem $grid-gutter-width/2;
			}
		}
	}

	.export-list-item {
		overflow: hidden;
		background-color: $grey-8;
		display:block;
	}

	.export-comment {
		background-color: $grey-8;
		text-align: center;
		width:100%;
		overflow: hidden;

		.comment-img {
			background-position: center;
			background-size: cover;
			height: 50rem;
			overflow: hidden;

		}

		.comment-customer {
			padding: 6rem $grid-gutter-width/2;

			img {
				box-shadow: 0 0 $grid-gutter-width/2 $grey-radius;
				border-radius: 40rem;
			}

			p {
				margin-bottom: 0;
				padding: 3.5rem 0 0;
				color: $grey-10;
				max-height: 18.5rem;
				overflow: hidden;
			}
		}
	}

	.tabs-item {
		position: relative;
		top: 0;
	    transform: translateY(0);
        margin: 5rem auto 0;
	    // min-height: 15rem;
	    height: 33rem;

	    &--1 {
			.desc {
				margin: 0 auto;
				min-height: 15rem;

				margin-top: 2rem;

				strong {
					color: $black;
					font-size: 2.2rem;
				}

				p {
					color: $grey-10;
					font-size: 16px;
					margin-bottom: 0;
				}
			}

			.thumb {
				width: 36rem;
				height: 15rem;
				text-align: center;
				margin: 0 auto;

				img {
					width: 80%;
					height: auto;
				}
			}

			// .thumb {

			// 	img {
			// 		// width: auto;
			// 		max-width: 100%;
			// 	}
			// }
	    }
	}

	.p-product {
		text-align: center;
		padding: 8rem $grid-gutter-width/2 0;
		margin-bottom: 3rem;
		overflow: hidden;

		p {
			font-size: 44px;
			color: $grey-3;
		}
	}

	.products {
		overflow: hidden;
		padding-bottom: 4rem;

		.row {
			overflow: hidden;
		}
	}

	.group-product {
	    text-decoration: none;
	    overflow: hidden;
	    display: block;
	    // margin-top: 2rem;

	    .tabs__content {
	    	height: auto;
	    	min-height: inherit;

	    	.content-tabs {

	    		width: 80%;
	    	}
	    }
	}

	//end trademark comment
	.sm {
		display:inline-block;
	}

	.tl {
		display:none;
	}

	.dk {
		display:none;
	}
}

.international {
	margin-bottom: 4rem;
	overflow: hidden;

	p {
		font-size: 15px;
		font-weight: 700;
		color: $white;
		text-align: center;
		text-transform: uppercase;
		padding: 0.7rem 2.7rem;
		margin-bottom: 0;
		float: left;
	}
}

.tabs--trademark {
    margin-top: 0;

	.export-desc {
		padding: 8rem 0;
		color: $white;
		text-align: center;
		width: 100%;

		.wrapper-img {
			padding-bottom: 3rem;
			overflow: hidden;
			width: 24rem;
			display: block;
			margin: 0 auto;
		}

		img {
			display: inline;
			width: 100%;
			margin: 0 auto;
		}

		p {
			font-size: 18px;
			line-height: 2;
			font-weight: 100;
			// text-align: left;
		}
	}
}
