// Block item

.bold-text {
	font-weight: 600;
}

.center {
	text-align: center;
}

.block {
	margin: 0 auto;
}

.bgd-grey {
	background-color: $grey-8;
}

.connect {
	background-color: $grey-8;
	overflow: hidden;
	padding-top: 8rem;
	padding-bottom: 8rem;

	.g-recaptcha {
		margin: 0 auto 2rem;
		max-width: 30.4rem;
	}

	.main-title--1 {
		font-weight: 600;
	}

	.error-message {
		left: 0;
		line-height: 2;
	}

	.custom-error {
		color: #d0021b;
		font-size: 1.4rem;
	}

	.mail-success {
		margin-bottom: 1rem;
	}

	&--change {
		max-width: 117rem;
	}

	&__send-mail {
		text-align: center;
		overflow: hidden;
		padding: 0 $grid-gutter-width/2;

		> p {
			font-weight: 300;
			padding-bottom: 1rem;
			text-align: left;

		}

		.send-mail {
			// max-width: 61.5rem;
			margin: 0 auto;
			overflow: hidden;

			.form-group {
				display: block;
				float: none;
				width: auto;
				margin-bottom: 2rem;
			}

			.input {
				text-align: center;
				border: none;
			}

			.button {
				float: none;
				display: block;
				width: 100%;

				&:hover, &:focus {
					box-shadow: none;
				}
			}
		}
	}

	&__address {
		padding: 0;
		margin-top: 3rem;
		overflow: hidden;

		span {
			display: inline-block;
			width: 25%;
		}

		p {
			line-height: 2;
			margin-bottom: 0;
			// font-size: 1.4rem;
			color: $grey-17;
		}

		.set-mb-xs {
			margin-bottom: 3rem;
		}
	}

	&__form {
		padding: 0;
		margin-top: 1.5rem;
		overflow: hidden;

		input, select, textarea {
			background-color: transparent;
			font-weight: 600;
			color: $color-fied-contact;
		}

		::-webkit-input-placeholder { /* WebKit, Blink, Edge */
			color: $font-color;
		};

		:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
			color: $font-color;
			font-weight: 300;
		};

		::-moz-placeholder { /* Mozilla Firefox 19+ */
			color: $font-color;
			font-weight: 300;
		};

		:-ms-input-placeholder { /* Internet Explorer 10-11 */
			color: $font-color;
		};

		.button--black {
			margin: 2rem auto 4rem;
			display: block;
		}

		p {
			color: $grey-17;
			line-height: 3.2rem;
			margin-bottom: 0;
		}

		.contact-form {
			margin-top: 2.5rem;
		}
	}
}

.contact {
	background-color: $grey-8;

	// .main-title--1 {
	// 	text-align: center;
	// 	font-size: 2.2rem;
	// 	font-weight: 600;
	// }
}

.follow {
	overflow: hidden;

	&__social {
		padding-top: 1rem;
		text-align: center;
		margin-left: -0.8rem;

		a {
			color: $grey-3;
			display: inline-block;
			margin-left: 5rem;
			transition: color 0.3s ease;
			position: relative;
			overflow: hidden;

			&:first-child {
				margin-left: 0;
			}

			&:hover {
				color: lighten($grey-3, 33.3%);
			}
		}

		.icon {
			font-size: 36px;
		}
	}

	.main-title--1 {
		text-align: center;
		font-weight: 600;
	}

}

.third-party-logos {
	margin-top: 3rem;

	&__logo {
		display: inline-block;
		width: 60px;

		img {
			width: 100%;
		}
	}
}

// History
.history-us {
	position: relative;
	padding: 9rem 0 15rem;

	// .sub-heading {
	// 	padding-left: 12.5rem;
	// 	font-size: 44px;
	// 	box-sizing: border-box;
	// }

	&__control {
		position: absolute;
		top: 2.3rem;
		right: 0;

		.btn--next {
			margin-left: 1.5rem;
		}
	}

	// &__content {
	// 	padding-left: 12.5rem;
	// 	margin-top: 5rem;

	// 	.desc {
	// 		padding-right: 9%;

	// 		h4 {
	// 			font-size: 60px;
	// 			color: $green-1;
	// 		}

	// 		p {
	// 			font-size: 16px;
	// 			line-height: 1.8;
	// 		}
	// 	}

	// 	.bold-text {
	// 		// font-size: 16px;
	// 		text-transform: uppercase;
	// 	}

	// 	.thumb {
	// 		margin-top: 5.5rem;

	// 		img {
	// 			width: 100%;
	// 		}
	// 	}
	// }
}

.trademark {
	margin: 10rem auto 0;
	// border-bottom: 1px solid $grey-1;
	overflow: hidden;

	&__content {
		max-width: 117rem;

		&:after {
			border-bottom: 1px solid $grey-1;
			width: 100%;
		}
	}

	.sub-heading {
		margin: 0 auto;
	}
}

.home-product {
	background-color: $grey-8;
	overflow: hidden;

	&__title {
		margin: 8rem auto;
		position: relative;
	}

	&__control {
		position: absolute;
		right: 0;
		top: 50%;
		margin-top: -2.2rem;
	}

	&__content {
		background-color: $white;

		.block {
			overflow: hidden;
		}

		.preview {
			ul {
				overflow: hidden;
				width: 1500rem;
			}

			li {
				float: left;
				width: 114rem;
			}
		}

		.columns {
			padding: 1.5rem;
		}

		img {
			width: 100%;
		}
	}
}

.product-area {
	margin: 8rem auto;
}

.style-guide-page {
	.container {
		margin: 2rem auto;
	}
}

.cover-history {
	position: relative;
}

/* Pagination */

.pagination {
	float: right;
	margin-bottom: 4rem;

	li {
		display: inline-block;
	}
}
