
$health-bg: #F8F8F8;
$text-news: #777777;
$border-top: #EEEEEE;
//begin product-deail page


// .header {
// 	border-bottom: 1px solid $border-top;
// }
//
.zoomContainer {
	transform: none !important;
}
.product-detail-page {
	margin-top: 6.3rem;

	.trademark {
		background-color: $white;
		margin: 0 auto;

		.sub-heading {
			margin: 0 auto;
		}

		.group-product {
			text-decoration: none;

			a:hover {
				position:0.7;
			}
		}
	}

	.detail-left {
		overflow: hidden;

		&__title {
			padding-top: 1.5rem;

			.title-detail {
				text-transform: uppercase;
				font-weight: 600;
				color: $black;
			}
		}

	}




	.detail-right {
		overflow: hidden;
		// width: 100%;

		h2 {
			font-weight: 200;
			font-size: 4.4rem;
		}

		p {
			color: $grey-10;
		}

		.title-detail {
			font-size: 1.6rem;
			text-transform: uppercase;
			font-weight: 600;
			color: $black;
			margin-bottom: 0;

		}

		.left, .right {
			width: 50%;
			float: left;
		}

		.right {
			padding-left: 3rem;
		}

		.icon-connect {
			a {
				font-size: 3rem;
				color: $grey-7;
				padding-right: 2rem;
				transition: opacity 0.3s ease;

				&:first-child {
					padding-left: 0;
				}

				&:last-child {
					padding-right: 0;
				}
			}
		}

		ul {
			list-style: disc;
			padding-left: 1.8rem;
			margin-bottom: 3.5rem;
		}

		.icon-connect {
			display: block;
		}

		button {
			margin-bottom: 3.5rem;
		}


	}

	.product-slider {
		position:relative;
		top:0;
		height: 100%;
		margin-bottom: 6rem;

		.detail-center {
			padding-top: 3rem;
			text-align: center;
			overflow: hidden;
			padding-bottom: 1.5rem;

			img {
				width: 86%;
			}
		}

		.dot-link {
			position: absolute;
			display: block;
			text-align: center;
			width: 100%;

			a {
				width: 5rem;
				display: inline-block;
				margin-bottom: 1rem;
				border: 1px solid $grey-1;

				&.active {
					border: 1px solid $green-1;
				}

				img {
					width: 100%;
				}
			}
		}
	}

	.detail-product {
		overflow: hidden;
		padding: 4rem 0;


	}


	.list-product-detail {
		overflow: hidden;
		position: relative;
		padding-bottom: 8rem;
		padding-top: 4rem;

		.p-product {
			text-align: center;

			p {
				font-size: 4.4rem;
				color: $grey-3;
			}
		}

		&__items {

			.products-items {
				padding: 10rem 1.5rem;
				padding-bottom: 0;
			}

			.thumb {
				text-align: center;
				width: 100%;

				img {
					width: 100%;
				}
			}

			.center {
				padding-top: 5.5rem;

				.title-strong {
					font-size: 2.2rem;
				}

				p {
					margin: 0 auto;
					margin-top: 2rem;
					height: 8rem;
					max-width: 70%;
				}
			}

		}

	}

	.health {
		background-color: $health-bg;
		margin: 0 auto;

		&__title {
			text-align: center;
			padding: 5rem 0 3rem;

			h2 {
				font-size: 4.4rem;

			}

		}

		&__content {
			overflow: hidden;
		}

		.news {
			padding:0;
			position: relative;



			&__desc {
				padding: 3rem 1.5rem;
				max-height: 36rem;
				height: auto;

				.date {
					color: $green-1;
					font-size: 1.1rem;
					font-weight: 700;
				}

				.title {
					font-size: 1.8rem;
					color: $black;
					font-weight: 500;
				}

				.content {
					color: $text-news;
				}
			}


			.news-detail {

				.video-news {
					height: 34rem;
					width: 100%;
					position: relative;
					display: block;
					overflow: hidden;

					&:before {
						content: '';
						background-color: rgba(0, 0, 0, 0.5);
						position: absolute;
						top: 0;
						left: 0;
						opacity: 1;
						width: 100%;
						height: 34rem;
					}
					&:hover {
						opacity: 1;
					}

					// .cover-block-video__play-btn {
					// 	position: absolute
					// 	top: 50%;
					// 	left: 50%;
					// 	transform: translate(-50, -50);
					// }
				}


				img {
					height: 34rem;
					width: 100%;
					padding-left: -1.5rem;
					padding-right: -1.5rem;
				}

			}
		}

	}




	// end product
}

// ul.share-buttons{
//   list-style: none;
//   padding: 0;
// }
