
.intro-page {

	.main-heading {
		font-size: 4.4rem;
	}
	.banner-item__content {
		text-align: center;
		i {
			padding-right: 1rem;
		}
		h3 {
			margin-bottom: 3.8rem;
		}
	}

	.image-logo {
		position: absolute;
		top: 0;
		left: 0;
		padding-top: 3rem;
		padding-left: 3rem;
	}
	.button {
		padding: 1.5rem 3rem;
		&--changes {
			padding: 1.4rem 3rem;
		}
	}

}