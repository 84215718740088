$color-h4: #513227;
$color-mennu-items: #0E4D84;

.guideAdmin-page {
	background-color: $grey-8;
	overflow: hidden;
	margin: 0 auto;


	h3 {
		font-weight: 500;
		color: $color-h4;
	}


///////////////// style component connect for admin page /////////////////
	.connect {
		background-color: $white;

		.follow {
			margin-left: 0;
		}

		.contact {
			display: none;
		}
	}

	.title-page {
		margin-top: 10rem;
		text-align: center;
		margin-bottom: 5rem;
		max-width: 117rem;

		h1 {
			font-size: 5rem;
			font-weight: 700;
		}

	}
	.image-align {
		text-align: center;
		margin: 0 auto;
	}


	.main-content {
		overflow: hidden;
		padding-bottom: 5rem;
		max-width: 117rem;
		color: $color-h4;


		a {
			font-weight: 500;
			color: $color-mennu-items;
		}


		.detail-guide {
			overflow: hidden;
			font-size: 2rem;

			h4 {
				font-weight: 500;
				color: $black;
			}

			h5 {
				font-weight: 500;
				color: $black;
				padding-left: 1rem;
			}


			img {
				width: 99%;
				box-shadow: 0 0 1rem $color-h4;
			}

			p, span {
				font-size: 1.6rem;
				font-weight: 600;
			}
		}

	}
}