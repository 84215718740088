.private-label-page {
	.introduce {
		padding: 0;

		&__content {
			overflow: hidden;
			// padding-top: 5rem;
			.introduce-text {
				padding: 8rem 2rem;
				// padding-top: 8rem;
				p {
					font-size: 16px;
					line-height: 2;
				}
			}
		}

		h2 {
			color: $grey-3;
			font-size: 44px;
		}

		.thumb {
			padding-left: 0;
			padding-top: 3.5rem;
			margin: 0 auto;

			&.bg-ome {
				background-size: cover;
				background-position: center;
				width: 100%;
				padding-bottom: 165%;
			}

			&.quality {
				background-size: cover;
				background-position: 50% center;
				width: 100%;
				padding-bottom: 130%;

				.title-heading {
					padding: 0 1.5rem;
					font-weight: 300;
				}
			}

		}
	}

	.panel-title {
		color: $black;
	}

	.panel-heading {
		padding-left: 2rem;
	}

	.private-list-item {
		overflow: hidden;
		background-color: $white;
	}

	.images-item {
		text-align: center;
		// margin-bottom: 2rem;
		img {
			padding-top: 2rem;
			max-width: 25rem;
		}

		p {
			padding-top: 2rem;
			font-weight: 500;
		}

	}

	.img-holdup {
		display: inline;
		float: left;
		width: 100%;
		text-align: center;

		img {
			width: auto;
			height: 30rem;
			padding: 0 1rem;
		}

		p {
			padding-top: 1.3rem;
		}
	}

	.private-product-list__header {

		display: none;


	}



	.demand {
		overflow: hidden;
		text-align: center;

		&__desc {
			padding: 5rem 1.5rem;
			text-align: left;
		}

		&__download-catalogue {
			padding: 1rem 2.5rem;

			.icon {
				font-size: 2rem;
				vertical-align: middle;
				margin-right: 1.5rem;
			}

			span {
				vertical-align: middle;
			}
		}
	}
	.read-more {
		text-align: center;
		display: inline-block;
		margin-top: 2rem;
		margin-bottom: 7%;
	}

	.read-more-content {
		width: 100%;
	}

	.display-mb {
		display: block;
	}
	.display-tl {
		display: none;
	}
	.display-dk {
		display: none;
	}
	.display-tabl-desk {
		display: none;
	}

	.review-360 {
		display: none;
	}

}
