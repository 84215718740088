.store {
	padding-top: 11.3rem;
	margin-bottom: 0;
}

.block-list-store {

	.store-address {

		.title-info {
			background: $grey16;
			padding: 2rem 1.5rem;
			font-weight: 600;
			text-transform: uppercase;
			position: relative;

			&:hover {
				cursor: pointer;
			}
		}

		.address-info {
			width: 100%;
			padding: 2rem 3rem;
			color: $grey-10;
		}

		&.active {

			.title-info {
				cursor: pointer;

				&:after {
					font-family: 'icons';
					position: absolute;
					right: 1rem;
					line-height: 1;
					// content: '';
					color: $black;
					width: 3rem;
					font-size: 2rem;
				}
			}
		}
	}

	&__title {
		line-height: 3rem;
		text-transform: uppercase;
		font-weight: 700;
		text-align: center;
	}

}

.block-list-map-store {
	margin-bottom: 4rem;

	img {
		width: 100%;
	}
}

.block-tab-store {
	display: block;
}

.list-tab-store {
	text-align: center;
	margin-bottom: 0;
	line-height: 3rem;

	li {
		margin-bottom: 0;
		overflow: hidden;
		display: inline-block;

		&.active {
			a {
				opacity: 1;
			}
		}

		a {
			text-indent: 9999px;
			display: inline-block;
			margin-right: 1rem;
			opacity: 0.6;
		}
	}
}

.tab-content {
	margin-top: 2rem;

	.tab-pane {
		display: none;
	}

	.active {
		display: block;
	}
}
