

//about-us
.about-page {
	height: 100%;
	.carousel--banner {
		height: auto;
		min-height: auto;
	}
	.main-heading {
    	font-size: 44px;
    	padding:0 2.5rem;
	}
	.content-arrow {
		padding:0 2.5rem;
	}

	.bottom-array {
		display:none;
	}


	.information {
		margin: 0 auto;
		height: auto;
		padding: 6rem 2.5rem 4rem;
		width: 100%;

		&__content {
			overflow: hidden;
			.information-text {
				padding-left: 0;
				p {
					color: $grey-10;
					font-size: 16px;
					line-height: 2;
				}
			}
		}


		h2 {
			color: $grey-3;
			font-size: 44px;
		}
		.thumb {
			padding: 0;
			margin: 0 auto;
			background-size: cover;
			background-position: center;
			width: 100%;
			padding-bottom: 50%;

			img {
				width: 100%;
				height: 100%;
			}

		}
	}

	//// list -item

	.about-list-item {
		overflow: hidden;
		background-color: $white;
		display:block;
	}
	.content-reward {
		overflow:hidden;
		padding-top: 8rem;
		padding-bottom: 8rem;
		color: $grey-10;
		text-align: justify;
		line-height: 2;

		.list-reward {
			display: block;
			overflow: hidden;
		}
		.certify {
			display: none;
			margin: 0 auto;
			text-align: center;
			padding-left: 0;
			padding-right: 0;
			padding-top: 2rem;

			img {
				display: none;
				// width: 100%;
				max-width: 40rem;
				height: 40rem;
			}

		}
		.certify-color {
			display: inline-block;
			margin: 0 auto;
			text-align: center;
			// padding-left: 0;
			// padding-right: 0;
			padding-top: 2rem;
			width: 100%;

			img {
				display: block;
				max-width: 25rem;
				padding-bottom: 4rem;
				margin: 0 auto;
			}
		}
		.sub-heading {
			// font-weight: 400;
			color: $black;
		}
	}




	.about-item {
		margin: 0 auto;


		.title {
			font-size: 44px;
		}

		p {
			font-size: 16px;
			line-height: 2;
			color: $grey-10;
		}

		&--1 {
			display: block;
			// width: 100%;

			.sub-heading {
				font-size: 44px;
				color: $black;
				// font-weight: 400;
			}

			.desc {
				// text-align: justify;
				// display: inline-block;
				vertical-align: middle;
				padding-top: 5.5rem;
				padding-bottom: 8rem;
			}
			.thumb {
				text-align: center;
				&.sharpen-imports {
					background-size: cover;
					background-position: center;
					height: auto;
					padding-bottom: 104%;
					// width: 100%;
				}

			}
		}
		&--2 {
			.sub-heading {
				font-size: 44px;
				color: $black;
				// font-weight: 400;
			}
			.desc {
				// text-align: justify;
				// display: inline-block;
				vertical-align: middle;
				padding-top: 8rem;
				padding-bottom: 8rem;
			}
			.thumb {
				text-align: center;
				&.sharpen-imports {
					background-size: cover;
					background-position: center;
					height: auto;
					padding-bottom: 104%;
					// width: 100%;
				}

			}
		}
	}




	.reward-list {
		margin: 0 auto;
		overflow: hidden;
		text-align: center;

		.reward__item {
			cursor: pointer;
			padding-top: 4rem;
			height: 35rem;
		}

		.img-black {
				display: none;
			}
		.img-color {
			display: block;
		}
	}




	.video-information {
		display: none;
	}

	.sm {
		display:inline-block;
	}
	.tl {
		display:none;
	}
	.dk {
		display: none;
	}
}
