
.news-detail {

	.one-news-item {
		margin-bottom: 0;

		&__content__image {
			width: 100%;
		}
	}

	.one-news-item__content {
		margin-bottom: 0;

		&::after {
				content: '';
				border-bottom: 1px solid rgb(255, 255, 255);
			}

	}

	.one-news-item__content__go-out {
		margin-bottom: 4rem;

		// .u-pull-left {

		// 	.icon {
		// 		padding-left: 3.2rem;
		// 		margin-right: -2rem;
		// 	}
		// }
	}

	.news-old {
		background-color: $grey-8;
		padding-top: 5rem;
		padding-bottom: 5rem;

		span {
			font-weight: 700;
		}
	}

	.news-left {
		text-align: left;
		font-weight: 400;
		padding-bottom: 5rem;

	}

	.news-right {
		text-align: left;
		font-weight: 400;
		padding-top: 5rem;
		border-top: 1px solid $grey-1;
		// width: 90%;

	}

	.title-news {
		font-weight: 700;
		text-align: left;
		padding-left: 1.5rem;

	}

}