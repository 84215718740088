//trademark-export
$blue-intro:   #201B51;

.trademark-omi-page {
	.introduce {
		.international {
			p {
				background-color: $blue-intro;
			}
		}
	}

	.tabs--trademark {
		.export-desc {
			background-color: $blue-intro;
		}
	}

	.js-content-animate-show {
		transform: translateY(0) !important;
	}
}


