//trademark-export
$red-intro:   #ED1C24;
$orange-bgintro: #FFFAF4;

.trademark-obeauty-page {
	.introduce {
		.international {
			p {
				background-color: $red-intro;
			}
		}
	}

	.tabs--trademark {
	    margin-top: 0;

		.export-desc {
			background-color: $orange-bgintro;

			p {
				color: $black;
			}
		}
	}
}