.search {
	margin: 0;
}

.search-wrapper {
	position: relative;

	input {
		width: 100%;
		border: 0;
		border-bottom: 1px solid $grey-8;
		padding-left: 1.5rem;
	}
}

.search-submit {
	position: absolute;
	right: 0.5rem;
	top: 0.7rem;
	border: 0;
	background: none;

	.icon {
		font-size: 2rem;
	}
}
