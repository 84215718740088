.map-distribute {

	&__green-overlay-container {
		display: none;
	}

	&__marker-container {
		display: none;
	}

	&__anotation {
		margin-top: 3rem;
		line-height: 2;

		&__icon {
			display: inline-block;
			vertical-align: middle;
			font-size: 2rem;
			margin-right: 0.5rem;

			&--green {
				color: $green-1;
			}
		}
	}
}

.map {

	&--desktop {
		display: none;
	}
}
