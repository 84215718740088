.header {
	width: 100%;
	position: fixed;
	top: 0;
	z-index: 54;
	height: 6.3rem;

	&--product-pages {
		background-color: $white;
	}

	&--light {

		.header__background {
			height: 6.3rem;
		}
	}

	&__background {
		position: absolute;
		bottom: 0;
		background-color: $white;
		width: 100%;
	}

	&__inner {
		padding-top: 1.5rem;
		height: 6.3rem;
		width: 100%;
		position: absolute;
		overflow: hidden;
	}

	&__trademark-popover {
		display: none;
	}
}

.navbar {
	display: none;
}

.navbar-toggle {
	margin: 0;
	padding: 0;
	height: 6.3rem;
	position: absolute;
	right: 1.5rem;
	top: 0;
	font-size: 2.5rem;
	border: 0;
}

.mennu-toggle {
	color: $grey-10;
	margin: 0;
	padding: 0;
	height: 6.3rem;
	position: absolute;
	right: 1.5rem;
	top: 0;
	font-size: 2.5rem;
	border: 0;
}

.language-popover {
	display: none;
}

.logo {

	img {
		width: 7.5rem;
	}
}

// Header on mobile
.nav-mobile {
	position: relative;
	width: 100vw;
	height: 100vh;
	float: right;
	transform: translateX(100%);
	transition: transform 1s cubic-bezier(0.19, 1, 0.22, 1);
	pointer-events: none;

	&.nav-mobile-open {
		opacity: 1;
		transform: translateX(0);
	}

	.nav-mobile-inner {
		position: absolute;
		top:0;
		left: 6rem;
		right: 0;
		width: 100%;
		height: 100vh;
		overflow-y: scroll;
		padding: 2rem 2.5rem;
		background-color: $black;
		pointer-events: visible;
	}

	a {
		color: $white;
	}

	.close {
		padding: 2rem;
		background-color: $white;
		line-height: 0;
		position: absolute;
		top: 0;
		left: 0;
		pointer-events: visible;


		i {
			font-size: 20px;
			color: $grey-10;
			transition: color 0.3s ease;
		}

		&:hover {
			i {
				color: $green-2;
			}
		}

	}

	.language-mb {
		li {
			margin-left: 3.5rem;
			margin-bottom: 0;
			float: left;
			font-size: 14px;

			&:first-child {
				margin-left: 0;
			}
		}

		a {
			transition: color 0.3s ease;
		}

		.active {
			color: $green-2;
		}
	}

	.nav-main {
		margin-top: 3.5rem;
		transition: height 0.5s ease;

		li {
			font-size: 20px;
			line-height: 4.8rem;
			text-transform: uppercase;
			margin-bottom: 0;

			> a {
				transition: font-weight 0.3s ease;

				&.active {
					font-weight: 600;
				}
			}
		}

	}

	.nav-util {
		list-style-type: none;

		.social-list {
			margin-top: 2rem;
			display: inline-block;
			margin-right: 2rem;

			.icon {
				font-size: 2.5rem;
			}
		}

		li:last-child {
			margin-bottom: 0;
		}

		a {
			font-size: 14px;
			line-height: 2;
			transition: color 0.3s ease;

			&.active {
				color: $green-2;
			}

		}

		i {
			font-size: 15px;
			vertical-align: middle;
			opacity: 0.8;
			padding-right: 0.5rem;
			top: -2px;
			width: 2.8rem;
			transition: color 0.3s ease;
		}

		.icon--recruit {
			font-size: 20px;
		}

		.icon--faq {
			font-size: 14px;
		}
	}

	.nav-mb-dropdown {
		margin-bottom: 0;
		margin-top: 0;
		height:0;
		opacity: 0;
		overflow: hidden;
		transition: opacity 0.5s ease, height 0.5s ease;

		a {
			text-transform: none;
		}
	}

	.has-dropdown {
		position: relative;
		width: 100%;

		.expand-dropdown {
			position: absolute;
			right: 6rem;
			top: 2rem;
			display: inline-block;
			line-height: 0;
			cursor: pointer;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0.8rem 0.6rem 0 0.6rem;
			border-color: $white transparent transparent transparent;
			transition: border-width 0.3s ease, border-color 0.3s ease;

		}

		a {
			&.active {
				& ~ .nav-mb-dropdown {
					opacity: 1;
					height: 15.5rem;
				}

				& ~ .expand-dropdown {
					border-width: 0 0.6rem 0.8rem 0.6rem;
					border-color: transparent transparent $green-2 transparent;
				}
			}
		}

	}
}

.overlay {
	background-color: rgba(0, 0, 0, 0.7);
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	opacity: 0;
	height: 0;
	transition: opacity 0.8s ease, height 0.8s ease;

	&.overlay-open {
		opacity: 1;
		min-height: 100vh;
	}
}
