// Home

@keyframes tooltip-animation {
	from {
		width: 100%;
		height: 100%;
		opacity: 1;
	}

	to {
		width: 200%;
		height: 200%;
		opacity: 0;
	}
}


.home-list-item {
	overflow: hidden;
	background-color: $grey-8;
}

.home-item {
	margin: 0 auto;
	padding: 8rem 0;

	.title {
		font-size: 44px;
	}

	p {
		font-size: 16px;
		line-height: 2;
	}

	.thumb {
		text-align: right;

		&.left {
			text-align: left;
		}

		&.right {
			text-align: right;
		}

		&.center {
			text-align: center;
		}
	}

	&--1 {
		padding-bottom: 0;
		// display: table;

		// .sub-heading {
		// 	text-transform: uppercase;
		// }

		.thumb, .desc {
			display: table-cell;
			vertical-align: middle;
			float: none;
		}
	}

	&--2 {
		position: relative;
		overflow: hidden;

		.thumb {
			img {
				width: 100%;
			}
		}

		.desc {
			color: $white;
			text-align: left;

			p {
				font-size: 16px;
			}
		}

		.button {
			margin-top: 3rem;
		}

		&__content {
			line-height: 2;
			color: $white;
		}

	}

	&--3 {
		padding-top: 0;

		.block {
			position: relative;
			min-height: 66.5rem;
			padding-bottom: 0;

			.div {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
			}
		}

		.thumb {
			z-index: 0;

			img {
				width: 100%;
			}
		}

		.list-tooltip {
			z-index: 1;
		}

	}

	&__big-image {
		padding-bottom: 62%;
		width: 100%;
		min-height: 50rem;
		background-position: center;
		background-size: cover;
	}
}

.list-tooltip-item {
	.tooltip-button {
		position: absolute;
		color: $white;
		border-radius: 100%;
		width: 2.4rem;
		height: 2.4rem;
		line-height: 2.4rem;
		background-color: $grey-9;

		&__ring {
			border: 2px solid $green-1;
			border-radius: 100%;
			display: none;
		}

		&.active {
			background-color: $green-1;

			.tooltip-button__ring {
				position: absolute;
				display: block;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				animation-name: tooltip-animation;
				animation-iteration-count: infinite;
				animation-delay: .3s;
				animation-duration: 1s;
			}

			&:before {
				transition: all .2s linear;
				transform: rotate(90deg) translate(-.8rem, .1rem);
			}
		}

		&:before {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			content: '';
			display: block;
			width: .3rem;
			height: 1.5rem;
			background-color: $white;
		}

		&:after {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			content: '';
			display: block;
			width: 1.5rem;
			height: .3rem;
			background-color: $white;
		}

		&:hover {
			background-color: $green-1;
		}
	}
}

//==========Trivo update=======//

.set-bg-cover {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}

.tabs--bg-view {
	@extend .set-bg-cover;
}

.tabs--bg-trade {
	@extend .set-bg-cover;
}


.tabs-item .desc .sub-heading {
	&--xs-private {
		font-size: 3.5rem;
	}
}

.tabs--bg-value {
	@extend .set-bg-cover;
	min-height: 80rem;

	&__sub-h4-title {
		font-size: 1.6rem;
		margin-top: 2rem;
		font-weight: 600;
	}
}

.tabs--bg-company {
	@extend .set-bg-cover;

	.tabs-item {
		transform: translateY(-70%);
	}

}

.list-position-detail {
	margin-bottom: 8rem;

	&__product-img {
		width: 100%;
		padding: 3rem 1.5rem 10rem;
	}
	.panel-collapse {

		padding: 0 1.5rem;
		&.collapsing {
			transition: all ease .35s;
		}
	}
}

.cover-block-video {
	position: relative;
	width: 100%;
	min-height: 25rem;
	// min-height: 25rem;
	margin-top: 5.5rem;

	transition: width .4s ease;
	animation-direction: alternate;

	&__checkimg-respon {
		max-width: 100%;
		height: auto;
	}

	&__overlay {
		// width: 100%;
		// height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		background: rgba(0, 0, 0, 0.5);
	}

	&__play-btn {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}

.behind-cover-block-video {
	position: relative;
	width: 100%;
	height: auto;
	margin-top: 5.5rem;
	display: none;

	&.playing {
		display: block;
	}
}

.btn-close {
    position: absolute;
    right: 1rem;
    top: 1rem;
    display: block;
    // z-index: 9999;
    padding: 1rem 2.5rem;
    background-color: $grey18;
    color: $white;
    text-transform: uppercase;

    span {
		position: relative;
		display: inline-block;
		width: 1.5rem;
		height: 1.5rem;
		overflow: hidden;
		margin-right: 0.5rem;

		&:before, &:after {
			content: '';
			position: absolute;
			height: 2px;
			width: 100%;
			margin-top: 0;
			background: $white;
			top: 50%;
			left: 0;

		}

		&:before {
		    transform: rotate(45deg);
		}

		&:after {
			transform: rotate(-45deg);
		}
    }
}

.control-height-block-sp {
	height: auto;
	width: 100%;
}

.bg-multi-kem {
	height: 100%;
	padding: 4rem 0 3rem;
	position: relative;
	width: 100%;
}


.homepage {

	.video-information {
		display: none;
	}

	.history-us {
		// height: 100vh;
		height: auto;
		min-height: 60rem;
		padding: 0;
		padding-bottom: 4rem;

		&__btn {
			display: none;
		}

		&__content {

			padding: 0 1.5rem;
			.desc {

				h4 {
					font-size: 60px;
					color: $green-1;
				}

				p {
					font-size: 16px;
					line-height: 1.8;
				}
			}

			.bold-text {
				// font-size: 16px;
				font-weight: bold;
			}

			.thumb {
				margin-top: 5.5rem;

				img {
					width: 100%;
				}
			}
		}

		&__control {
			margin-top: -2.2rem;
		}

		.set-margin-top {
			margin-top: 3rem;
		}

		&__header2 {
			padding-top: 6rem;
			padding-bottom: 3rem;
		}

		&__text {
			padding-top: 2rem;
			margin-bottom: 4rem;
		}

		&__image {
			padding-bottom: 0%;
			position: relative;
			display: block;
			img {
				visibility: hidden;
				max-width: 100%;
				height: auto;
			}
		}

	}

	.home-item--3 {
		position: relative;
		padding-bottom: 0;
	}
}

.bgkem-item-3 {
	padding-bottom: 0%;
	position: relative;
	margin: 0 auto;
	max-width: 27rem;
	margin-bottom: 8rem;

	img {
		visibility: hidden;
	}
}

.trademark {
	.sub-heading {
		text-align: left;
		margin-bottom: 2rem;
	}

	&--subcribe-branch {
		margin-top: 1.3rem;
		font-size: 1.6rem;
		line-height: 2;
	}
}

.list-logo-branch {
	margin: 3rem 0;

	ul {
		margin-bottom: 0;

		li {
			width: 50%;
			display: inline-block;
			margin-bottom: 3rem;
			vertical-align: middle;

			a {
				display: block;
				text-align: center;
				width: 80%;
				margin: 0 auto;

				img {
					max-width: 80%;
					height: auto;
				}
			}
		}
	}
}

.distribute {
	background: $white;
	padding: 7rem 0 6rem;
	max-width: 100%;
	overflow: hidden;

	&__heading {
		margin-bottom: 6rem;
	}

	.color-maps-feature {
		li {
			position: relative;
			padding-left: 3rem;
			margin-bottom: 0.7rem;

			&:before {
				width: 1rem;
				height: 1rem;
				position: absolute;
				content: '';
				left: 1rem;
				top: 0.6rem;
			}

			&:first-child {
				&:before {
					background-color: $green1;
				}
			}

			&:nth-child(2) {
				&:before {
					background-color: $green2;
				}
			}
		}
	}
}

.footer {
	.text-aside-left, .text-aside-right {
		text-align: center;
	}
}

.time-list-xs {
	margin-bottom: 0;
	padding: 4rem 1.5rem 0;
	width: 100%;
	box-sizing: border-box;
	background: $grey-12;

	&--fixed {
		position: fixed;
		width: 100%;
		top: 6.3rem;
		z-index: 2;
	}

	.active {
		&:before {
			content: " ";
			position: absolute;
			left: 50%;
			top: -1.2rem;
			transform: translate(-50%);
			height: 1.5rem;
			width: 0.5rem;
			background-color: $black;
		}
	}

	li {
		display: inline-block;
		position: relative;
		font-size: 1.1rem;
		width: 10%;
		margin-bottom: 0;
		text-align: center;

		&:before {
			content: "|";
			position: absolute;
			left: 50%;
			top: -1.2rem;
			transform: translate(-50%);
			line-height: 1;
		}

		a {
			color: $font-color;
			padding: 1rem 0;
			display: inline-block;
			line-height: 1;
			width: 100%;
			font-weight: 400;
		}

		&:last-child {
			width: 16%;
		}

		&:nth-child(even) {
			a {
				position: relative;
				top: -4rem ;
				left: 50%;
				transform: translateX(-50%);
			}

			.active {
				&:before {
					top: 2.8rem;
				}
			}
		}
	}

}

.text-center {
	text-align: center;
}
