// scss-lint:disable SpaceAfterVariableName

// Skeleton Variables
//––––––––––––––––––––––––––––––––––––––––––––––––––

// Breakpoints
$bp-min-phablet   : 'min-width: 550px' !default;
$bp-min-tablet    : 'min-width: 750px' !default;
$bp-min-desktop   : 'min-width: 1000px' !default;
$bp-min-desktophd : 'min-width: 1200px' !default;

// Colors
$white           : #fff !default;
$black           : #000 !default;
$light-grey      : #ddd !default;
$dark-grey       : #333 !default;
$primary-color   : #33c3f0 !default;
$secondary-color : lighten($dark-grey, 13.5%) !default;
$border-color    : #ddd !default;
$link-color      : lighten($black, 10.5%) !default;
$link-color-hover: lighten($black, 40.5%) !default;
$font-color      : #222 !default;
$selection-color : #b3d4fc;
$grey-1          : #ddd;
$grey-2          : #cdcdcd;
$grey-3          : #202020;
$grey-4          : #3c3d41;
$grey-5          : #979797;
$grey-6          : #3e3f40;
$grey-7          : #9b9b9b;
$grey-8          : #f6f6f6;
$grey-9          : #4a4a4a;
$grey-10         : #595959;
$grey-11         : #c3c3c4;
// $green-1         : #71bf43;
$green-1         : #3ab24a;
$green-2         : #3ab24a;
$red             : #f00;
$red-1           : #d0021b;
$grey-light		 : #efefef;
$grey-12		 : #f2f2f0;
$grey-13		 : #2a3c3e;
$grey-14		 : #9a9a9a;
$grey15			 : #4b4b4b;
$grey16			 : #f5f5f5;
$grey-17		 : #62696d;
$green1			 : #71bf43;
$green2			 : #b8e986;
$bg              : #111;
$color-fied-contact: #3d3b40;

$border-contact: #d6d6d6;
$grey18: #191919;
$grey19: #ebebeb;
$border-news: #e1e1e1;


// Typography
$font-family: 'Open Sans', Helvetica, Arial, sans-serif !default;
$base-font-size: 16px;


//== Media queries breakpoints
//
//## Define the breakpoints at which your layout will change, adapting to different screen sizes.

// Extra small screen / phone
//** Deprecated `$screen-xs` as of v3.0.1
$screen-xs:                  480px !default;
//** Deprecated `$screen-xs-min` as of v3.2.0
$screen-xs-min:              $screen-xs !default;
//** Deprecated `$screen-phone` as of v3.0.1
$screen-phone:               $screen-xs-min !default;

// Small screen / tablet
//** Deprecated `$screen-sm` as of v3.0.1
$screen-sm:                  768px !default;
$screen-sm-min:              $screen-sm !default;
//** Deprecated `$screen-tablet` as of v3.0.1
$screen-tablet:              $screen-sm-min !default;

// Medium screen / desktop
//** Deprecated `$screen-md` as of v3.0.1
$screen-md:                  992px !default;
$screen-md-min:              $screen-md !default;
//** Deprecated `$screen-desktop` as of v3.0.1
$screen-desktop:             $screen-md-min !default;

// Large screen / wide desktop
//** Deprecated `$screen-lg` as of v3.0.1
$screen-lg:                  1200px !default;
$screen-lg-min:              $screen-lg !default;
//** Deprecated `$screen-lg-desktop` as of v3.0.1
$screen-lg-desktop:          $screen-lg-min !default;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max:              ($screen-sm-min - 1) !default;
$screen-sm-max:              ($screen-md-min - 1) !default;
$screen-md-max:              ($screen-lg-min - 1) !default;


//== Grid system
//
//## Define your custom responsive grid.

//** Number of columns in the grid.
$grid-columns:              12 !default;
//** Padding between columns. Gets divided in half for the left and right.
$grid-gutter-width:         30px !default;
// Navbar collapse
//** Point at which the navbar becomes uncollapsed.
$grid-float-breakpoint:     $screen-sm-min !default;
//** Point at which the navbar begins collapsing.
$grid-float-breakpoint-max: ($grid-float-breakpoint - 1) !default;


//== Container sizes
//
//## Define the maximum width of `.container` for different screen sizes.

// Small screen / tablet
$container-tablet:             (720px + $grid-gutter-width) !default;
//** For `$screen-sm-min` and up.
$container-sm:                 $container-tablet !default;

// Medium screen / desktop
$container-desktop:            (940px + $grid-gutter-width) !default;
//** For `$screen-md-min` and up.
$container-md:                 $container-desktop !default;

// Large screen / wide desktop
$container-large-desktop:      (1140px + $grid-gutter-width) !default;

//** For `$screen-lg-min` and up.
$container-lg:                 $container-large-desktop !default;


// Misc
$global-radius: 4px !default;
