	.bg-baner {
	    position: relative;
		.banner-item {
			background: $bg ;
			background-size: cover;
			background-position: center center;
			float:left;
			height: 100vh;
			width:100%;

			&__content {
			    width: 100%;
			    margin-left: 0;
			    left: 50%;
			    top: 50%;
			    transform: translate(-50%, -50%);
			    // padding: 0 2.5rem;
				transition: transform 3s cubic-bezier(0.19, 1, 0.22, 1), opacity 2s cubic-bezier(0.19, 1, 0.22, 1);
			    text-align: left;

			    p {
			    	width:100%;
			    }
			}
		}

		.main-heading {
	    	font-size: 44px;
	    	padding:0 $grid-gutter-width/2;
		}

		.content-arrow {
			padding:0 $grid-gutter-width/2;
		}

	}
	.arrow-tab {
		display: none;
	}
	.arrow-des {
		display: none;
	}
