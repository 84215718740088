.product-list-page {

	.product-list-wrapper {
		margin-bottom: 5.5rem;
		margin-left: 7rem;
	}

	.product-list {
		//display: none;
		margin-top: 15.5rem;

		// &.active {
		// 	display: block;
		// }

		&--obeauty {

			.product__thumb {
				height: 25rem;

				img {
					height: 100%;
					width: auto;
				}
			}
		}
	}

	.product {
		border-bottom: 1px solid $grey-8;
		text-align: center;
		padding-top: 2.5rem;
		padding-bottom: 2.5rem;

		a {
			display: block;
		}

		&__thumb {
			width: 100%;
			display: block;
			overflow: hidden;

			img {
				width: 80%;
			}

		}

		&__serial {
			font-weight: 600;
			margin-top: 4rem;
			display: block;

			> span {
				font-weight: 300;
				display: none;
			}
		}

		&__price {
			font-size: 2rem;
			font-weight: 600;
		}

	}


	&__footer {
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		padding: 1.5rem;
		background-color: $green-1;
		text-align: center;
		z-index: 56;

		a {
			color: $white;
			font-weight: 600;
		}
	}

	.product-list-filter {
		z-index: 99;
		position: fixed;
		left: 0;
		top: 6.3rem;
		bottom: 0;
		right: 0;
		width: 100%;
		height: 100vh;
		border: 1px solid $grey-8;
		background-color: $white;
		padding: 2.5rem 1.5rem 0;
		display: none;


		&__header {
			font-size: 3.6rem;
		}

		// &__body {
		// 	padding-bottom: 10rem;
		// }

		&__child {
			padding: 1rem 0;
			margin-bottom: 1rem;
			border-bottom: 1px solid $grey-8;

			&__header {
				font-weight: 600;
				margin-bottom: 2rem;
			}

			&__choices {
				font-size: 0;
				margin-left: -1rem;
			}

			&__choice {
				text-transform: uppercase;
				background-color: $grey-11;
				display: inline-block;
				width: 8rem;
				padding: 0.5rem 1.5rem;
				margin: 0 1rem 1.5rem;
				font-size: 1.4rem;
				line-height: 2rem;
				font-weight: 400;
				color: $white;
				text-align: center;
				cursor: pointer;
				border-radius: 2rem;
				transition: background-color 0.3s ease;

				&.active {
					background-color: $green-1;
				}
			}

			&--colors {

				.product-list-filter__child__choice {
					opacity: 0.4;
					height: 3rem;
					transition: opacity 0.3s ease;

					&.active {
						opacity: 1;
					}

				}
			}
		}

		&__footer {
			position: absolute;
			width: 100%;
			bottom: 6.3rem;
			padding: 1.5rem;
			font-weight: 700;
			background-color: $grey-8;
			left: 0;

			.icon {
				margin-right: .5rem;
			}

			a {
				color: $black;

				&.disable {
					color: $grey-11;
				}
			}
		}
	}
}
