
.newspage {
	display: block;
}



.main-content {
	display: block;
}

.list-news {
	display: block;
	margin-bottom: 2rem;
	margin-top: 5rem;

}

.one-news-item {

	&__publish-time {
		text-align: center;
		height: auto;
		display: inline-block;
		clear: both;
		margin-top: 3rem;
		margin-bottom: 2rem;

		&__day {
			font-size: 1.8rem;
			color: $grey-14;
			border-right: 1px solid $grey19;
			float: left;
			margin: 0;
			padding-right: 1.5rem;

			li {
				font-size: 2.4rem;
				font-weight: 700;
				margin: 0;
				line-height: 1;

				&:last-child {
					font-weight: 600;
					font-size: 2rem;
				}
			}
		}

		&__icon {
			padding-left: 1.5rem;
			line-height: 2.8;
			display: inline-block;
			color: $light-grey;
			vertical-align: middle;

			img {
				width: 90%;
			}
		}
	}

	&__content {
		margin: 0 auto;

		&::after {
			content: '';
			border-bottom: 1px solid $grey19;
			width: 100%;
			text-align: center;
			display: block;
		}


		&__title {
			font-size: 2.4rem;
			font-weight: 700;
			margin-bottom: 2rem;

			a {
				color: $black;
			}
		}

		&__imgdesc {
			margin-bottom: 2rem;
			padding-bottom: 67%;
			margin-left: -$grid-gutter-width/2;
			margin-right: -$grid-gutter-width/2;
		}

		&__short {
			color: $grey15;
			margin-bottom: 4rem;
			line-height: 2;
			text-align: justify;
		}

		&__go-out {
			overflow: hidden;

			ul {
				margin-bottom: 0;

				li {
					margin-bottom: 4rem;

					&:nth-child(3) {
						text-align: right;
					}

					a {
						color: $grey15;
					}
				}
			}
		}
	}

	&:last-child {

		.one-news-item__content {
			&:after {
				border: none;
			}
		}
	}
}

.icon-margin {
	margin-right: 1rem;
}

.block-menu-right {
	margin-bottom: 4rem;

	a {
		font-weight: 400;
	}
}
.icon-news {
	display: none;
}
.pagination {
	display: none;
}

.sidebar-right-xs {
	position: absolute;
	width: 100%;

	a {
		font-weight: 700;
		padding: 2rem 3rem 2rem 1.5rem;
		border: none;
		border-bottom: 1px solid $grey-light;
		text-transform: uppercase;
	}
}
