/**
 * Generated by gulp-iconfont, see _icons.scss in template
 * Customised by Thanh Tran.
 */




@font-face {
	font-family: 'icons';
	src: url('../fonts/icons.eot');
	src: url('../fonts/icons.eot?#iefix') format('embedded-opentype'),
		// url('../fonts/icons.woff2') format('woff2'),
		url('../fonts/icons.woff') format('woff'),
		url('../fonts/icons.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

.icon {
	font-family: 'icons';
	display: inline-block;
	// vertical-align: middle;
	line-height: 1;
	font-weight: normal;
	font-style: normal;
	speak: none;
	text-decoration: inherit;
	text-transform: none;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}


.icon--add:before { content: '\ea01'; }

.icon--area-dot:before { content: '\ea02'; }

.icon--arr-bottom:before { content: '\ea03'; }

.icon--arrow-left-01:before { content: '\ea04'; }

.icon--arrow-next:before { content: '\ea05'; }

.icon--arrow-prev:before { content: '\ea06'; }

.icon--arrow-right:before { content: '\ea07'; }

.icon--back:before { content: '\ea08'; }

.icon--beauty:before { content: '\ea09'; }

.icon--camera-detail:before { content: '\ea0a'; }

.icon--celender:before { content: '\ea0b'; }

.icon--clear-filter:before { content: '\ea0c'; }

.icon--clippers:before { content: '\ea0d'; }

.icon--close:before { content: '\ea0e'; }

.icon--cloud-download:before { content: '\ea0f'; }

.icon--corf:before { content: '\ea10'; }

.icon--cuticle-nipper:before { content: '\ea11'; }

.icon--del:before { content: '\ea12'; }

.icon--deluxe-pusher:before { content: '\ea13'; }

.icon--export:before { content: '\ea14'; }

.icon--facebook-detail:before { content: '\ea15'; }

.icon--facebook:before { content: '\ea16'; }

.icon--faq:before { content: '\ea17'; }

.icon--folder:before { content: '\ea18'; }

.icon--home:before { content: '\ea19'; }

.icon--icon-list-menu:before { content: '\ea1a'; }

.icon--icon-toggle:before { content: '\ea1b'; }

.icon--instagram:before { content: '\ea1c'; }

.icon--langua:before { content: '\ea1d'; }

.icon--left:before { content: '\ea1e'; }

.icon--login:before { content: '\ea1f'; }

.icon--logo:before { content: '\ea20'; }

.icon--mail:before { content: '\ea21'; }

.icon--manicure-set:before { content: '\ea22'; }

.icon--map-marker:before { content: '\ea23'; }

.icon--nail-art-dotting-tools:before { content: '\ea24'; }

.icon--nail-file:before { content: '\ea25'; }

.icon--nail-nipper:before { content: '\ea26'; }

.icon--nail-polishes:before { content: '\ea27'; }

.icon--nail-sharp:before { content: '\ea28'; }

.icon--nail-statistic:before { content: '\ea29'; }

.icon--next:before { content: '\ea2a'; }

.icon--nursing-paint:before { content: '\ea2b'; }

.icon--omi:before { content: '\ea2c'; }

.icon--painted:before { content: '\ea2d'; }

.icon--pitu-detail:before { content: '\ea2e'; }

.icon--pre:before { content: '\ea2f'; }

.icon--recruit:before { content: '\ea30'; }

.icon--scissors-eyebrow-scissors:before { content: '\ea31'; }

.icon--scissors-haircuts-scissors:before { content: '\ea32'; }

.icon--scissors-nose-hair-scissors:before { content: '\ea33'; }

.icon--scissors:before { content: '\ea34'; }

.icon--search:before { content: '\ea35'; }

.icon--share-social:before { content: '\ea36'; }

.icon--sleek-paint:before { content: '\ea37'; }

.icon--store:before { content: '\ea38'; }

.icon--sub:before { content: '\ea39'; }

.icon--tick:before { content: '\ea3a'; }

.icon--toggle:before { content: '\ea3b'; }

.icon--trademark-icon:before { content: '\ea3c'; }

.icon--tweezers:before { content: '\ea3d'; }

.icon--twitter-detail:before { content: '\ea3e'; }

.icon--twitter:before { content: '\ea3f'; }

.icon--view:before { content: '\ea40'; }

.icon--youtube:before { content: '\ea41'; }
